<template>
  <transition :appear="true" name="slide">
  <div class="content">
    <div class="main">
      <div class="title">活动设置</div>
      <van-form @submit="onSubmit">
        <!-- <van-field
          v-model="form.activity_start_time"
          name="activity_start_time" @click="showdate=true"
          label="活动开始时间" readonly
          placeholder="活动开始时间"
          :rules="[{ required: true, message: '请选择活动开始时间' }]"
        /> -->
        <van-field
          v-model="form.answer_time"
          name="answer_time"
          label="答题时间/秒" type="digit"
          placeholder="每轮答题时间/秒"
          :rules="[{ required: true, message: '请填写每轮答题时间' }]"
        />
        <van-field
          v-model="form.user_num"
          name="user_num"
          label="获奖人数" type="digit"
          placeholder="获奖人数"
          :rules="[{ required: true, message: '请填写获奖人数' }]"
        />
        <!-- <van-field
          v-model="columns[form.islun]" @click="islunshow=true"
          label="轮答方式"
          placeholder="请选择轮答方式"
        /> -->
        <van-field
          v-model="form.ques_num"
          name="ques_num"
          label="题的数量" type="digit"
          placeholder="请填写题的数量"
          :rules="[{ required: true, message: '请填写题的数量' }]"
        />
        <div class="bot-btn">
          <van-button class="btn" round block @click="onSubmit(0, form)">开始报名</van-button>
          <van-button class="btn" round block @click="onSubmit(1, form)">开始答题</van-button>
        </div>
      </van-form>
      <van-action-sheet v-model="showdate" title="选择活动开始时间">
        <van-datetime-picker
          v-model="form.currentDate"
          type="datetime"
          title="" @confirm="seldatetime"
        />
      </van-action-sheet>
      <van-action-sheet v-model="islunshow" title="选择轮答方式">
        <van-picker
          title=""
          show-toolbar @confirm="onConfirm"
          :columns="columns"
        />
      </van-action-sheet>
    </div>
  </div>
  </transition>
</template>

<script>
import { getActivitiesDetail, setactivity } from '@/api/Activity'
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
export default {
  name: 'AnswerSetting',
  components: {
  },
  computed: {
    ...mapGetters(['agencyGuid', 'userGuid'])
  },
  data () {
    return {
      showOverlay: false,
      username: '',
      phone: '',
      userinfo: {},
      form: { currentDate: new Date(), ques_num: '', answer_time: 120, user_num: 2, activity_guid: '', activity_start_type: 0 },
      showdate: false,
      activity_guid: '',
      activity: {},
      columns: ['手动', '自动'],
      islunshow: false
    }
  },
  watch: {
  },
  created () {
    let query = this.$route.query
    this.activity_guid = query.acguid ? query.acguid : ''
    if (this.activity_guid !== '') {
      this.form.activity_guid = this.activity_guid
      this._getActivitiesDetail()
    }
  },
  methods: {
    onConfirm (value, index) {
      console.log(`当前值：${value}, 当前索引：${index}`)
      this.form.islun = index
      this.islunshow = false
    },
    seldatetime (e) {
      let date = new Date(e)
      // 提取年份、月份、日期、小时、分钟和秒
      let year = date.getFullYear()
      let month = String(date.getMonth() + 1).padStart(2, '0') // 月份从0开始，所以需要+1
      let day = String(date.getDate()).padStart(2, '0')
      let hours = String(date.getHours()).padStart(2, '0')
      let minutes = String(date.getMinutes()).padStart(2, '0')
      // let seconds = String(date.getSeconds()).padStart(2, '0')
      // 拼接成 YYYY-MM-DD HH:mm:ss 格式
      let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`
      // let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      this.form.activity_start_time = formattedDate
      this.showdate = false
    },
    onSubmit (type, values) {
      console.log(values)
      values.activity_start_type = type
      this._setactivity()
    },
    _setactivity () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      setactivity(this.form).then(res => {
        this.$toast.success(res.msg)
        this.$toast.clear()
      })
    },
    _getActivitiesDetail () {
      let _this = this
      getActivitiesDetail({ activity_guid: this.activity_guid, c_user_guid: this.userGuid }).then(res => {
        if (res.code === 200) {
          _this.activity = res.data
          _this.form.activity_start_time = res.data.activity_start_time
          _this.form.answer_time = res.data.activitySetting.answer_time
          _this.form.ques_num = res.data.activitySetting.ques_num
          _this.form.user_num = res.data.activitySetting.user_num
          _this.form.activity_start_type = res.data.activity_start_type
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.content{
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
  width:100%;
  height:100%;
  background-image:url('~@/assets/img/activity/editinfo_bg.png')
  background-repeat:no-repeat;
  background-size:100% 100%;
  z-index 10
}
.main{
  padding:30px;
  background-color:#ffffff;
  width: calc(100% - 160px);
  height: 575px;
  background: #FFFFFF;
  border-radius: 24px 0px 24px 0px;
  margin-left:auto;
  margin-right:auto;
  margin-top:410px;
  box-shadow: 0px 5px 10px #eeeeee;
  position relative
  .title{
    height:36px;
    line-height:100px;
    wdith:100%;
    text-align:center;
    margin-bottom:58px;
  }
  .van-cell{
    line-height:30px;
  }
  .bot-btn{
    position:absolute;
    bottom:70px;
    width:calc(100% - 90px);
    text-align:center;
    padding:0 30px;
    justify-content: space-between;
    display: flex;
    .btn{
      width: 40%;
      height: 88px;
      background: #BE0404;
      box-shadow: 0px 4px 13px 0px rgba(190,4,4,0.47);
      border-radius: 44px;
      color:#ffffff;
      border:none;
      float:left;
    }
  }
}
</style>
